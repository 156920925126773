import { useEffect, useRef, useState } from "react";



import { alertError, alertSuccess } from "../../actions/AlertActions";
import { assignSrToContractor, assignTicketToContractor, getVendors } from "../../api/Api";
import { Category, VendorData } from "../../pages/vendors/service";
import { properString } from "../../utils/StringUtils";
import { PartySubscriberIcon, SearchIcon } from "../common/AppIcons";


type AssignTicketprops = {
  ticketIdOrSrId: number;
  isSr: boolean;
  onSuccess: () => void;
};

export default function AssignTicketOrSr({
  ticketIdOrSrId,
  isSr = false,
  onSuccess,
}: AssignTicketprops) {
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState<VendorData[]>([]);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [currPage, setCurrPage] = useState(0); // storing current page number
  const [totalPages, setTotalPages] = useState(10); // storing list



  useEffect(() => {
    setCurrPage(0);
    setListData([]);
  }, [searchText]);

  useEffect(() => {
    getVendors({ page: currPage, size: 5 }, "CONTRACTOR", searchText)
      .then((data) => {
        setListData((prevData) =>
          currPage === 0 ? data.content : [...prevData, ...data.content]
        );
        console.log(data.totalPages);

        setTotalPages(data.totalPages);
      })
      .catch((_) =>
        alertError("Couldn't Connect to Server, Check your Connection")
      );
  }, [searchText, currPage]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (currPage !== totalPages) {
          setCurrPage(currPage + 1);
        }
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };

  const handleClickItem = (SubscriberId: number) => {
    (isSr
      ? assignSrToContractor(ticketIdOrSrId, SubscriberId)
      : assignTicketToContractor(ticketIdOrSrId, SubscriberId)
    )
      .then(() => {
        alertSuccess("Succesfully Assigned");
        onSuccess();
      })
      .catch(() => {
        alertError("Error occured");
      });
  };

  return (
    <div className="flex flex-col">
      <div className="text-xl font-semibold text-black mb-5">
        Assign {isSr ? "Service Request #SR" : "Ticket #T"}
        {ticketIdOrSrId}
      </div>
      <p className="font-semibold text-newBlue-400 w-full">
        <div className="relative ">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
            placeholder="Search..."
            required
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      </p>
      <div
        className="flex flex-col mt-4 h-112 overflow-auto"
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {listData.map((item: VendorData) => (
          <button
            className="flex flex-row p-4 border-b border-newGray-600"
            onClick={() => handleClickItem(item.id)}
          >
            <div className="flex justify-center items-center h-14 w-14 rounded bg-yellow-100">
              {" "}
              <PartySubscriberIcon />
            </div>
            <div className="flex flex-col ml-3 w-full items-start">
              <div className="text-lg font-medium text-black">{item.name}</div>
              <div
                className={`grid grid-flow-row-dense grid-rows-1 grid-cols-3 mt-1 `}
              >
                {item.categories?.map((category: Category) => (
                  <>
                    {category.subCategories?.map((subCat: string) => (
                      <div className="flex flex-row min-w-max items-center justify-center bg-yellow-100 m-1 p-1 rounded text-base text-newGray-1200 font-normal ">
                        {properString(subCat)}
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}