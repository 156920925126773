import React, { useEffect, useRef, useState } from "react";
import SockJsClient from "react-stomp";

import { CircularProgress, CssBaseline } from "@mui/material";
import {
  StyledEngineProvider, // adaptV4Theme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { RecoilRoot } from "recoil";

import AlertWrapper from "./AlertWrapper";
import { alertError } from "./actions/AlertActions";
import { dispatchNotification } from "./actions/NotificationActions";
import { updateLastOnline } from "./actions/UserAction";
import AppLayout from "./components/common/AppLayout";
import ChatBox from "./components/common/ChatBox";
import ErrorWrap from "./components/common/ErrorWrap";
import CRMControls from "./components/crm/CRMControls";
import NewCRMControls from "./components/crm/NewCRMControls";
import ChatComponent from "./components/crm/chat/ChatComponent";
import { useCheckMobileScreen } from "./components/crm/chat/utils/useMatchMedia";
import dispatcher from "./dispatcher";
import AppRouter from "./router/AppRouter";
import PublicRouter from "./router/PublicRouter";
import ActiveUserStore from "./store/ActiveUserStore";
import { DBConfig } from "./store/DBConfig";
import { initDB } from "./utils/indexed-db/indexed-hooks";

try {
  initDB(DBConfig);
} catch (error) {
  alertError(
    "Couldn't Initialize IndexedDB in your browser",
    "Some functionalities may not work as expected. Please try clearing your browser cache and reloading the page."
  );
}

const zIndex = {
  mobileStepper: 10,
  speedDial: 15,
  appBar: 20,
  drawer: 25,
  modal: 30,
  snackbar: 45,
  tooltip: 50,
};

const theme = createTheme({
  zIndex,
  palette: {
    primary: { main: "#3f51b5" },
    // secondary: { main: "#F1B929" },
    type: "dark",
  },
  components: {
    // MuiSvgIcon: {
    //   styleOverrides: {
    //     root: {
    //       "&.MuiSvgIcon-root": {
    //         color: "#000"
    //       }
    //     }
    //   }
    // },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiTab-root": {
            padding: "6px 12px",
            color: "",
          },
          "min-width": "160px",
          "&.Mui-selected": {
            borderRadius: "25px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&.MuiOutlinedInput-input": {
            padding: "18.5px 14px",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
    },
  },
});

//

export default function App() {
  const isDesktopResolution = !useCheckMobileScreen();
  const [callWidget, setCallWidget] = useState(false);
  const [activeUser, setActiveUser] = useState({ loading: true });
  const [topics, setTopics] = useState([
    "/notifications/" + activeUser.id,
    "/notifications/global",
  ]);
  const clientRef = useRef(null);

  const [crm, setCRM] = useState(false);

  const handleAction = (action) => {
    switch (action.actionType) {
      case "CRM":
        console.log("CRM Triggered", action);
        setCRM(action.open);
        break;
      case "SUBSCRIBE":
        console.log("Subscribing to ::" + action.topic);
        setTopics((topics) => [...topics, action.topic]);
        break;
      case "UNSUBSCRIBE":
        console.log("Unsubscribing from ::" + action.topic);
        setTopics((topics) => topics.filter((topic) => topic !== action.topic));
        break;
      default:
    }
  };
  useEffect(() => {
    setActiveUser(ActiveUserStore.getActiveUser());
    ActiveUserStore.on("change", () => {
      setActiveUser(ActiveUserStore.getActiveUser());
    });

    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;

    let timer1 = setTimeout(() => updateLastOnline(), 5 * 60 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div className="h-screen w-full">
      <RecoilRoot>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {activeUser.loading ? (
              <div className="flex items-center h-screen">
                <CircularProgress className="mx-auto" />
              </div>
            ) : ActiveUserStore.isLoggedIn() ? (
              <AppLayout
                isAdmin={ActiveUserStore.isAdmin() || ActiveUserStore.isSuper()}
                isHelpSquad={ActiveUserStore.isHelpSquad()}
              >
                <div className="pb-40 md:pb-10 lg:pb-0 pt-0 md:pt-10 lg:pt-0">
                  <AppRouter isHelpSquad={ActiveUserStore.isHelpSquad()} />
                </div>
                {/* Mount only in Production */}
                {process.env.NODE_ENV === "production" && (
                  <ErrorWrap name="Call Controls">
                    {isDesktopResolution ? (
                      <NewCRMControls
                        setCRM={setCRM}
                        crm={crm}
                        setCallWidgetCB={setCallWidget}
                      />
                    ) : (
                      <CRMControls setCallWidgetCB={setCallWidget} />
                    )}
                  </ErrorWrap>
                )}
                <ChatBox />
                <ChatComponent />
                {process.env.NODE_ENV !== "development" && (
                  <SockJsClient
                    url={`${process.env.REACT_APP_BASE_URL}/ws-sockjs`}
                    topics={topics}
                    onConnect={(_) => {}}
                    // onConnectFailure={(_) =>
                    // console.log("Couldn't Establish Connection")
                    // } //alertError("Error Connecting")}
                    onMessage={(msg, topic) => {
                      console.log("Notification:" + msg.content);
                      dispatchNotification({ data: msg, topic: topic });
                    }}
                    ref={clientRef}
                    // debug
                  />
                )}
              </AppLayout>
            ) : (
              <PublicRouter />
            )}
            <AlertWrapper callWidget={callWidget} />
          </ThemeProvider>
        </StyledEngineProvider>
      </RecoilRoot>
    </div>
  );
}
