import moment from "moment";
import "moment-timezone";

import { alertError, alertSuccess, alertInfo } from "../actions/AlertActions";
import { scheduleSR } from "./Api";

export const schedule = (newSchedule, onSuccess) => {
  let startTime = moment(new Date(newSchedule.startTime));

  let requiredTimezoneOffset = moment().tz(newSchedule.timezone)?.utcOffset();

  let endTime = moment(new Date(newSchedule.endTime));

  startTime = startTime.subtract(requiredTimezoneOffset, "minutes");
  endTime = endTime.subtract(requiredTimezoneOffset, "minutes");

  alertInfo("Scheduling Please Wait...");

  scheduleSR({
    appointmentId: newSchedule.appointmentId,
    appointmentType: newSchedule.appointmentType,
    serviceRequestId: newSchedule.serviceRequestId,
    associateId: newSchedule.associateId,
    timelineStart: startTime.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    timelineEnd: endTime.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    shouldNotify: newSchedule.shouldNotify,
    appointmentDescription: newSchedule.description,
    remedial: newSchedule.isRemedial,
  })
    .then((_) => {
      alertSuccess("Successfully Scheduled");
      onSuccess && onSuccess();
    })
    .catch((err) => {
      if (err.readableMessage) {
        alertError(err.readableMessage);
      } else {
        alertError("Error Scheduling");
      }
    });
};
