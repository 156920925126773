import React, { useEffect, useState, useReducer } from "react";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Select, MenuItem } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { is } from "date-fns/locale";
import moment from "moment";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import { updateSr, updateMaterial } from "../../api/Api";
import { isValidDecimal } from "../../utils/NumberUtils";
import { deepUpdate } from "../../utils/StateUtils";
import { properString } from "../../utils/StringUtils";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { GreenTickIcon } from "../clients/ClientAppIcons";
import { DocumentKanbanIcon, InvoiceIcon } from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import MaterialForm from "../common/form/MaterialForm";

const tableHeader = ({ sectionName, showPremiumHeader, estimate }) => (
  <li key="header" className="flex py-8 text-sm sm:items-center">
    <div className="ml-4 grid flex-auto grid-cols-1 grid-rows-1 items-start gap-x-5 gap-y-3 sm:ml-6 sm:flex sm:items-center sm:gap-0">
      {/* Serial Number */}
      <div key="serial-number" className="row-end-1 flex-auto sm:pr-3 w-2">
        <p className="font-medium text-gray-700 ">{"#"}</p>
      </div>

      {/* Services & Materials */}
      <div
        key="services-materials"
        className="row-end-1 flex-auto sm:pr-3 w-20"
      >
        <p className="font-medium text-gray-700">
          <span>Item</span>
        </p>
      </div>

      {/* Description */}
      <div key="description" className="row-end-1 flex-auto sm:pr-3 w-28">
        <p className="font-medium text-gray-700">
          <span>Description</span>
        </p>
      </div>

      {/* Quantity */}
      <div
        key="quantity"
        className="row-end-1 flex-auto sm:pr-3 w-6  text-center"
      >
        <p className="font-medium text-gray-700">
          <span>Qty</span>
        </p>
      </div>

      {/* Rate */}
      <div key="rate" className="row-end-1 flex-auto sm:pr-3 w-12 text-center">
        <p className="font-medium text-gray-700">
          <span>Rate</span>
        </p>
      </div>

      {/* Premium */}
      {showPremiumHeader && (
        <div
          key="premium"
          className="row-end-1 flex-auto sm:pr-3 w-12 text-center"
        >
          <p className="font-medium text-gray-700">
            <span>Premium</span>
          </p>
        </div>
      )}

      {/* Amount */}
      <div
        key="amount"
        className="row-end-1 flex-auto sm:pr-3 w-12 text-center"
      >
        <p className="font-medium text-gray-700">
          <span>Amount</span>
        </p>
      </div>

      {/* Accepted By */}
      <div
        key="accepted-by"
        className="row-end-1 flex-auto sm:pr-3 w-24 text-center"
      >
        <p className="font-medium text-gray-700">
          <span>
            {estimate.estimateStatus === "ACKNOWLEDGED"
              ? "Acknowledged By"
              : "Accepted By"}
          </span>
        </p>
      </div>

      {/* Accepted On */}
      <div key="accepted-on" className="row-end-1 flex-auto sm:pr-3 w-20">
        <p className="font-medium text-gray-700">
          <span>
            {estimate.estimateStatus === "ACKNOWLEDGED"
              ? "Acknowledged On"
              : "Accepted On"}
          </span>
        </p>
      </div>

      {/* Invoiced */}
      {sectionName === "ACCEPTED" && (
        <div key="invoiced" className="row-end-1 flex-auto sm:pr-3 w-5">
          <p className="font-medium text-green-500">
            <InvoiceIcon className="h-5 w-5 " />
          </p>
        </div>
      )}
    </div>
  </li>
);

export const updateServiceRequest = (srId, quantity, baseRate, premium) => {
  return updateSr(srId, [
    {
      op: "replace",
      path: "/quantity",
      value: Number(quantity),
    },
    {
      op: "replace",
      path: "/baseRate",
      value: Number(baseRate),
    },
    {
      op: "replace",
      path: "/premium",
      value: Number(premium),
    },
  ]);
};

const updateMaterialItem = (materialId, quantity, baseRate, premium) => {
  return updateMaterial(materialId, [
    {
      op: "replace",
      path: "/quantity",
      value: Number(quantity),
    },
    {
      op: "replace",
      path: "/baseRate",
      value: Number(baseRate),
    },
    {
      op: "replace",
      path: "/premium",
      value: Number(premium),
    },
  ]);
};

function updateItem(item, action) {
  let updatedValue;
  switch (action.type) {
    case "setQuantity":
      updatedValue = "quantity";
      break;
    case "setBaseRate":
      updatedValue = "baseRate";
      break;
    case "setPremium":
      updatedValue = "premium";
      break;
    default:
      return item;
  }
  console.log("LOGG: quantity", item.quantity);
  console.log("LOGG: baseRate", item.baseRate);
  console.log("LOGG: premium", item.premium);
  console.log("LOGG: amount", item.amount);

  const updatedItem = {
    ...item,
    [updatedValue]: Number(action.value),
  };

  console.log(
    "LOGG: updatedItem",
    updatedItem.quantity,
    typeof updatedItem.baseRate,
    typeof updatedItem.premium,
    updatedItem.amount
  );

  // Recalculate amount based on the updated values
  updatedItem.amount =
    (updatedItem.quantity || 0) * (updatedItem.baseRate || 0);

  // Apply premium if it exists
  if (updatedItem.premium !== undefined) {
    updatedItem.amount =
      (updatedItem.quantity || 0) *
      ((updatedItem.baseRate || 0) + (updatedItem.premium || 0));
  }

  return updatedItem;
}

function reducer(state, action) {
  switch (action.type) {
    case "setQuantity":
    case "setBaseRate":
    case "setPremium":
      return {
        ...state,
        [action.sectionName]: {
          ...state[action.sectionName],
          lines: state[action.sectionName].lines.map((product) => {
            if (product.id === action.id && !action.isMaterial) {
              // Update product
              return updateItem(product, action);
            } else if (product.materials) {
              // Update material
              return {
                ...product,
                materials: product.materials.map((material) => {
                  if (material.materialId === action.id && action.isMaterial) {
                    return updateItem(material, action);
                  }
                  return material;
                }),
              };
            }
            return product;
          }),
        },
      };
    case "setEstimateItems":
      return action.estimateItems;
    default:
      return state;
  }
}

export default function EstimateTableNew({
  estimateItems,
  isExternal,
  setSelected,
  multiSelect,
  openInvoiceModal,
  qbInvoicedLines,
  refreshCB,
  vendorDetails,
  estimate,
}) {
  const isMobile = useMediaQuery("(max-width: 640px)");

  const [editItemMobile, setEditItemMobile] = useState({
    display: false,
    edit: false,
    data: {},
  });

  const [premiumType, setPremiumType] = useState("percentage");
  const [premiumInputs, setPremiumInputs] = useState({});

  useEffect(() => {
    console.log("LOG: editItemMobile", editItemMobile);
  }, [editItemMobile]);

  const showCheckBox = (product, sectionName, isMaterial = false) => {
    // If multiSelect is undefined, return false
    if (multiSelect === undefined) {
      return false;
    }
    // Return false for all materials
    else if (isMaterial) {
      return false;
    }

    // If multiSelect is "ACKNOWLEDGE_ESTIMATE", return true for products in the Received From TPV section
    else if (
      multiSelect === "ACKNOWLEDGE_ESTIMATE" &&
      sectionName === "RECEIVED"
    ) {
      return true;
    }

    // If multiSelect is "ACCEPT_ESTIMATE", return true for all products, not in the Accepted section
    else if (multiSelect === "ACCEPT_ESTIMATE" && sectionName !== "ACCEPTED") {
      return true;
    }

    // If multiSelect is "PARTIAL_INVOICE", return true for all products, in the Accepted section
    else if (
      multiSelect === "PARTIAL_INVOICE" &&
      sectionName === "ACCEPTED" &&
      !product.invoiceId
    ) {
      return true;
    }
    // Return false for all other cases
    else {
      return false;
    }
  };

  const [items, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    dispatch({
      type: "setEstimateItems",
      estimateItems: estimateItems,
    });
  }, [estimateItems]);

  const formItemUpdate = (data) => {
    return [
      {
        name: "name",
        label: "Name",
        editable: false,
      },
      {
        name: "description",
        label: "Description",
        editable: false,
      },
      {
        name: "quantity",
        label: "Quantity",
        editable: data.isReadonly ? false : data.editBaseRate || false,
      },
      data.showBaseRate && {
        name: "baseRate",
        label: "Rate",
        editable: data.isReadonly ? false : data.editBaseRate || false,
      },
      data.showRate && {
        name: "rate",
        label: "Rate",
        editable: false,
      },
      data.showPremium && {
        name: "premiumType",
        label: "Premium Type",
        inputType: "SELECT",
        options: [
          { value: "absolute", label: "$" },
          { value: "percentage", label: "%" },
        ],
        editable: data.isReadonly ? false : data.editPremium || false,
      },
      data.showPremium && {
        name: "premium",
        label: "Premium",
        editable: data.isReadonly ? false : data.editPremium || false,
      },
    ].filter(Boolean);
  };

  return (
    <div className="flex w-full flex-col gap-2">
      {items &&
        Object.entries(items).map(([sectionName, section]) => {
          return (
            section.lines.length > 0 && (
              <div
                key={sectionName}
                className={"shadow rounded overflow-auto w-full"}
              >
                <form className="flex flex-col w-full bg-white py-4 sm:rounded-lg sm:pb-6 ">
                  <div className="flex items-center flex-grow justify-between sm:px-1 lg:px-4">
                    <h2 className="text-base font-medium text-gray-700 ml-2">
                      {properString(sectionName)}
                    </h2>
                    <h2 className="text-base font-medium text-green-500 mr-2">
                      $ {parseFloat(section.total).toFixed(2)}
                    </h2>
                  </div>

                  <section aria-labelledby="cart-heading">
                    <ul
                      role="list"
                      className="divide-y divide-gray-200 sm:px-1 lg:px-2"
                    >
                      {!isMobile &&
                        tableHeader({
                          sectionName,
                          showPremiumHeader: section.showPremiumHeader,
                          estimate,
                        })}
                      {section.lines.map((product, productIdx) => {
                        return (
                          <React.Fragment key={product.id}>
                            {isMobile ? (
                              itemCard(product, productIdx + 1, sectionName)
                            ) : (
                              <>
                                {tableItem(
                                  product,
                                  productIdx + 1,
                                  sectionName,
                                  false,
                                  section.showPremiumHeader
                                )}
                                {product.materials &&
                                  product.materials.map(
                                    (material, materialIdx) =>
                                      tableItem(
                                        material,
                                        productIdx +
                                          1 +
                                          "." +
                                          (materialIdx + 1),
                                        sectionName,
                                        true,
                                        section.showPremiumHeader
                                      )
                                  )}
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </section>
                </form>
              </div>
            )
          );
        })}

      {editItemMobile.display && (
        <MaterialModal
          open={editItemMobile.display ? true : false}
          setOpen={(_) => {
            setEditItemMobile((current) => {
              return {
                data: {},
                display: false,
                edit: false,
              };
            });
          }}
          label="new-user-modal"
          describedby="create-new-user"
          containerClassName="max-h-[80vh] w-2/3 overflow-y-auto"
        >
          <div className="flex material-popup">
            <div>
              <Typography variant="h6">{editItemMobile.data.title} </Typography>
              <MaterialForm
                data={editItemMobile.data}
                renderArray={formItemUpdate(editItemMobile.data)}
                onChange={(update) => {
                  const { name, value } = update;

                  if (name === "premiumType") {
                    // Convert premium value when type changes
                    const currentPremium = editItemMobile.data.premium;
                    const baseRate = editItemMobile.data.baseRate;
                    const newPremium =
                      value === "percentage"
                        ? ((currentPremium / baseRate) * 100).toFixed(2)
                        : ((currentPremium * baseRate) / 100).toFixed(2);

                    setEditItemMobile((current) => ({
                      ...current,
                      data: {
                        ...current.data,
                        premiumType: value,
                        premium: newPremium,
                      },
                    }));
                  } else if (name === "premium") {
                    // Handle premium value changes
                    if (
                      value === "" ||
                      value === "." ||
                      /^\d*\.?\d*$/.test(value)
                    ) {
                      const calculatedValue =
                        value === "" || value === "."
                          ? 0
                          : editItemMobile.data.premiumType === "percentage"
                          ? (parseFloat(value) / 100) *
                            editItemMobile.data.baseRate
                          : parseFloat(value);

                      setEditItemMobile((current) => ({
                        ...current,
                        data: {
                          ...current.data,
                          premium: value,
                          calculatedPremium: calculatedValue, // Store the actual value to be sent to API
                        },
                      }));
                    }
                  } else {
                    setEditItemMobile((current) => ({
                      ...current,
                      data: deepUpdate(name, value, editItemMobile.data),
                    }));
                  }
                }}
              />
              <button
                onClick={(_) => {
                  console.log("LOG: editItemMobile.data", editItemMobile.data);
                  (editItemMobile.data.isMaterial
                    ? updateMaterialItem(
                        editItemMobile.data.id,
                        editItemMobile.data.quantity || 0,
                        editItemMobile.data.baseRate || 0,
                        editItemMobile.data.calculatedPremium || 0
                      )
                    : updateServiceRequest(
                        editItemMobile.data.id,
                        editItemMobile.data.quantity || 0,
                        editItemMobile.data.baseRate || 0,
                        editItemMobile.data.calculatedPremium || 0
                      )
                  )
                    .then((_) => {
                      alertSuccess("Successfully Updated Item");
                      refreshCB();
                      setEditItemMobile({
                        display: false,
                        data: {},
                        edit: false,
                      });
                    })
                    .catch((_) => {
                      alertError("Error Updating Item");
                    });
                }}
                color="primary"
                className="float-right p-4 material-create"
              >
                Update
              </button>
            </div>
          </div>
        </MaterialModal>
      )}
    </div>
  );

  function tableItem(
    product,
    productIdx,
    sectionName,
    isMaterial = false,
    showPremiumHeader = false
  ) {
    return (
      <li
        key={product.id}
        className={`flex py-5 text-sm sm:items-center rounded-lg ${
          isMaterial ? "bg-newGray-100 text-gray-300" : ""
        } `}
      >
        <div className="ml-4 grid flex-auto grid-cols-1 grid-rows-1 items-start gap-x-5 gap-y-3 sm:ml-6 sm:flex sm:items-center sm:gap-0">
          <div className="row-end-1 flex-auto sm:pr-3 w-2">
            {showCheckBox(product, sectionName, isMaterial) ? (
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-0"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setSelected((selected) => {
                    if (checked) {
                      return [...selected, product];
                    } else {
                      return selected.filter(
                        (selected_product) => selected_product.id !== product.id
                      );
                    }
                  });
                }}
              />
            ) : (
              <p className="font-medium text-gray-700">{productIdx}</p>
            )}
          </div>
          <div className="row-end-1 flex-auto sm:pr-3 w-20">
            <p className="font-medium text-gray-700">
              {product.title} {product.name}
            </p>
            {product.displayVendor && (
              <p className="text-xs ml-1 px-1 py-0.5 rounded bg-gray-300 text-gray-700 w-min">
                {product.displayVendor.name}
              </p>
            )}
          </div>
          <div className="row-end-1 flex-auto sm:pr-3 w-32">
            <p className="text-gray-700">{product.description}</p>
          </div>
          <div className="row-end-1 flex-auto sm:pr-2 w-6">
            <input
              id={`quantity-${productIdx}`}
              name={`quantity-${productIdx}`}
              value={product.quantity}
              disabled={isReadonly() || !product.editBaseRate}
              title={disabledMessage()}
              onBlur={(e) => {
                (isMaterial
                  ? updateMaterialItem(
                      product.materialId,
                      e.target.value || 0,
                      product.baseRate,
                      product.premium
                    )
                  : updateServiceRequest(
                      product.id,
                      e.target.value || 0,
                      product.baseRate,
                      product.premium
                    )
                )
                  .then((_) => {
                    refreshCB();
                  })
                  .catch((_) => {});
              }}
              onChange={(e) => {
                if (isValidDecimal(e.target.value)) {
                  dispatch({
                    type: "setQuantity",
                    sectionName: sectionName,
                    id: isMaterial ? product.materialId : product.id,
                    isMaterial: isMaterial,
                    value: Number(e.target.value),
                  });
                }
              }}
              type="text"
              className="block w-full rounded-md border border-gray-300 px-0.5 py-1.5 text-right text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          {product.showRate && (
            <div className="row-end-1 flex-auto sm:pr-2 w-12">
              <input
                id={`rate-${productIdx}`}
                name={`rate-${productIdx}`}
                value={product.rate}
                disabled={true}
                type="text"
                className="block w-full rounded-md border border-gray-300 px-0.5 py-1.5 text-right text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          )}
          {product.showBaseRate && (
            <div className="row-end-1 flex-auto sm:pr-2 w-12">
              <input
                id={`baseRate-${productIdx}`}
                name={`baseRate-${productIdx}`}
                value={product.baseRate}
                disabled={isReadonly() || !product.editBaseRate}
                title={disabledMessage()}
                onBlur={(e) => {
                  if (!product.showPremium) {
                    (isMaterial
                      ? updateMaterialItem(
                          product.materialId,
                          product.quantity,
                          e.target.value || 0,
                          product.premium
                        )
                      : updateServiceRequest(
                          product.id,
                          product.quantity,
                          e.target.value || 0,
                          product.premium
                        )
                    )
                      .then((_) => {
                        refreshCB();
                      })
                      .catch((_) => {});
                  }
                }}
                onChange={(e) => {
                  if (!product.showPremium) {
                    if (isValidDecimal(e.target.value)) {
                      dispatch({
                        type: "setBaseRate",
                        sectionName: sectionName,
                        id: isMaterial ? product.materialId : product.id,
                        isMaterial: isMaterial,
                        value: Number(e.target.value),
                      });
                    }
                  }
                }}
                type="text"
                className="block w-full rounded-md border border-gray-300 px-0.5 py-1.5 text-right text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          )}
          {product.showPremium && (
            <div className="row-end-1 flex-auto sm:pr-2 w-16 flex">
              <div className="relative flex w-full">
                <input
                  id={`premium-${productIdx}`}
                  name={`premium-${productIdx}`}
                  value={
                    premiumInputs[product.id] ??
                    (premiumType === "percentage"
                      ? ((product.premium / product.baseRate) * 100).toFixed(2)
                      : product.premium)
                  }
                  disabled={isReadonly() || !product.editPremium}
                  title={disabledMessage()}
                  onBlur={(e) => {
                    const inputValue = e.target.value || "0";
                    const calculatedValue =
                      premiumType === "percentage"
                        ? (parseFloat(inputValue) / 100) * product.baseRate
                        : parseFloat(inputValue);

                    setPremiumInputs((prev) => ({
                      ...prev,
                      [product.id]: null,
                    }));

                    (isMaterial
                      ? updateMaterialItem(
                          product.materialId,
                          product.quantity,
                          product.baseRate,
                          calculatedValue
                        )
                      : updateServiceRequest(
                          product.id,
                          product.quantity,
                          product.baseRate,
                          calculatedValue
                        )
                    )
                      .then((_) => refreshCB())
                      .catch((_) => {});
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      inputValue === "" ||
                      inputValue === "." ||
                      /^\d*\.?\d*$/.test(inputValue)
                    ) {
                      setPremiumInputs((prev) => ({
                        ...prev,
                        [product.id]: inputValue,
                      }));

                      if (inputValue !== "" && inputValue !== ".") {
                        const calculatedValue =
                          premiumType === "percentage"
                            ? (parseFloat(inputValue) / 100) * product.baseRate
                            : parseFloat(inputValue);

                        dispatch({
                          type: "setPremium",
                          sectionName: sectionName,
                          id: isMaterial ? product.materialId : product.id,
                          isMaterial: isMaterial,
                          value: calculatedValue,
                        });
                      }
                    }
                  }}
                  type="text"
                  className="block w-full rounded-l-md border border-r-0 border-gray-300 px-0.5 py-1.5 text-right text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                />
                <Select
                  value={premiumType}
                  onChange={(e) => {
                    setPremiumType(e.target.value);
                    setPremiumInputs({});
                  }}
                  className="rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-xs"
                  sx={{
                    width: "24px",
                    minWidth: "24px",
                    "& .MuiSelect-select": {
                      padding: "6px 4px 6px 4px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiSvgIcon-root": {
                      right: "-1px",
                      width: "12px",
                    },
                  }}
                >
                  <MenuItem value="percentage">%</MenuItem>
                  <MenuItem value="absolute">$</MenuItem>
                </Select>
              </div>
            </div>
          )}
          {!product.showPremium && showPremiumHeader && (
            <div className="row-end-1 flex-auto sm:pr-2 w-12"></div>
          )}
          <div className="row-end-1 flex-auto  text-right w-12">
            <p className="font-medium text-gray-700">
              ${parseFloat(product.amount).toFixed(2)}
            </p>
          </div>
          <div className="row-end-1 flex-auto sm:pr-3 w-24 text-center">
            <p className="font-medium text-gray-700">{product.acceptedName}</p>
          </div>
          <div className="row-end-1 flex-auto sm:pr-3 w-20">
            <p className="font-medium text-gray-700">
              {product.acceptedDate &&
                moment(product.acceptedDate).format("MM/DD/YYYY")}
            </p>
          </div>
          <div className="row-end-1 flex-auto sm:pr-3 w-4">
            {!isMaterial &&
              (product.invoiceId ? (
                <div
                  title="Open Invoice"
                  onClick={() => openInvoiceModal(product.invoiceId)}
                  className="cursor-pointer"
                >
                  <GreenTickIcon className="w-4" />
                </div>
              ) : qbInvoicedLines.find((line) => line.id === product.id) ? (
                <a
                  href={
                    process.env.REACT_APP_ESTIMATE +
                    qbInvoicedLines.find((line) => line.id === product.id).txnId
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GreenTickIcon className="w-4" />
                </a>
              ) : product.srBookingStatus !== "COMPLETED" &&
                sectionName === "ACCEPTED" ? (
                <div
                  title="Incomplete Service Request"
                  className="text-yellow-600"
                >
                  <ScheduleIcon className="w-4" />
                </div>
              ) : null)}
          </div>
        </div>
      </li>
    );

    function disabledMessage() {
      return multiSelect
        ? "Cannot modify while Accepting Estimate or Invoicing"
        : isExternal
        ? "Cannot modify items under External Tickets"
        : sectionName === "ACCEPTED"
        ? "Cannot modify Accepted items"
        : "";
    }

    function isReadonly() {
      return isExternal || multiSelect || sectionName === "ACCEPTED";
    }
  }

  function itemCard(product, productIdx, sectionName) {
    return (
      <div
        className={
          "flex flex-col p-2 m-2 lb rounded cursor-pointer hover:bg-gray-300 bg-white kanban-cards border-gray-200 border-2"
        }
        onClick={() => onClickEditItem(product, false)}
      >
        <div className="flex items-center">
          <div className="flex flex-row justify-between w-full mb-1">
            {showCheckBox(product, sectionName) && (
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-0 cursor-pointer"
                onChange={(e) => {
                  console.log("LOG: e.target.checked", e.target.checked);
                  const checked = e.target.checked;
                  setSelected((selected) => {
                    if (checked) {
                      return [...selected, product];
                    } else {
                      return selected.filter(
                        (selected_product) => selected_product.id !== product.id
                      );
                    }
                  });
                }}
                onClick={(e) => e.stopPropagation()}
              />
            )}
            <div className="flex justify-center w-20 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal py-0.5">
              {product.title}
            </div>
            <div className="self-end">
              <span
                className="inline-flex items-center rounded px-2 py-0.5 text-sm font-medium"
                style={{ color: "#4B4B4B" }}
              >
                ${parseFloat(product.amount).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-grow justify-between w-full">
          <p className="text-sm ml-0.5" style={{ color: "#0023FF" }}>
            {product.description}
          </p>

          <div className="mr-8 w-4 ">
            {product.invoiceId ? (
              <div
                title="Open Invoice"
                onClick={(e) => {
                  openInvoiceModal(product.invoiceId);
                  e.stopPropagation();
                }}
                className="cursor-pointer"
              >
                <GreenTickIcon className="w-4" />
              </div>
            ) : qbInvoicedLines.find((line) => line.id === product.id) ? (
              <a
                href={
                  process.env.REACT_APP_ESTIMATE +
                  qbInvoicedLines.find((line) => line.id === product.id).txnId
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <GreenTickIcon className="w-4" />
              </a>
            ) : product.srBookingStatus !== "COMPLETED" &&
              sectionName === "Accepted" ? (
              <div
                title="Incomplete Service Request"
                className="text-yellow-600"
              >
                <ScheduleIcon className="w-4" />
              </div>
            ) : null}
          </div>
        </div>

        <div
          container
          justifyContent="space-between"
          spacing={3}
          className="grid grid-cols-2 w-full justify-between gap-x-24"
        >
          <div item>
            {
              <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus">
                Qty:
                {product.quantity}
              </span>
            }
          </div>
          {product.showBaseRate && (
            <div>
              {
                <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus">
                  Rate: ${product.baseRate}
                </span>
              }
            </div>
          )}

          {product.rate && (
            <div>
              {
                <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus">
                  Rate: ${product.rate}
                </span>
              }
            </div>
          )}

          {product.showPremium && (
            <div>
              {
                <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus">
                  Premium:
                  {((product.premium / product.baseRate) * 100).toFixed(2)} %
                </span>
              }
            </div>
          )}

          <div>
            {product.acceptedName && (
              <span
                className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium"
                style={{
                  color: "#23AF2A",
                }}
              >
                {product.acceptedName}
              </span>
            )}
          </div>
          <div>
            {product.acceptedDate && (
              <span
                className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium"
                style={{
                  color: "#23AF2A",
                }}
              >
                {moment(product.acceptedDate).format("MM/DD/YYYY")}
              </span>
            )}
          </div>
        </div>
        {product.materials && product.materials.length > 0 && (
          <div className="flex flex-col p-0.5 m-0.5 rounded cursor-pointer hover:bg-gray-300 bg-white border-gray-200 border-2">
            <p className="text-sm pt-1 pb-0.5 text-gray-700 ">Materials</p>
            <div className="grid grid-cols-1 gap-0.5">
              {product.materials &&
                product.materials.map((material, materialIdx) =>
                  materialCard(material, materialIdx, sectionName)
                )}
            </div>
          </div>
        )}
      </div>
    );
  }

  function materialCard(material, materialIdx, sectionName) {
    return (
      <div
        className={
          "flex flex-col p-0.5 py-1 cursor-pointer hover:bg-gray-300 bg-white"
        }
        onClick={(e) => {
          onClickEditItem(material, true, sectionName);
          e.stopPropagation();
        }}
      >
        <div className="flex flex-row justify-between w-full mb-1">
          <div className="flex justify-center w-20 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal py-0.5">
            {material.name}
          </div>
          <div className="self-end">
            <span
              className="inline-flex items-center rounded px-2 py-0.5 text-xs font-medium"
              style={{ color: "#4B4B4B" }}
            >
              ${parseFloat(material.amount).toFixed(2)}
            </span>
          </div>
        </div>
        <p className="text-xs ml-0.5" style={{ color: "#0023FF" }}>
          {material.description}
        </p>
      </div>
    );
  }
  function onClickEditItem(product, isMaterial, sectionName) {
    setEditItemMobile({
      display: true,
      edit: true,
      data: {
        isMaterial: isMaterial,
        name: isMaterial ? product.name : product.title,
        description: product.description,
        quantity: product.quantity,
        amount:
          (product.baseRate || 0 + product.premium || 0) * product.quantity ||
          0,
        id: isMaterial ? product.materialId : product.id,
        baseRate: product.baseRate,
        premium:
          premiumType === "percentage"
            ? ((product.premium / product.baseRate) * 100).toFixed(2)
            : product.premium,
        premiumType: premiumType,
        rate: product.rate,
        showBaseRate: product.showBaseRate,
        showRate: product.showRate,
        showPremium: product.showPremium,
        editBaseRate: product.editBaseRate,
        editPremium: product.editPremium,
        sectionName: sectionName,
        isReadonly: isExternal || multiSelect || sectionName === "ACCEPTED",
      },
    });
  }
}
