import React, { useEffect } from "react";

import moment from "moment";

import { properString } from "../../utils/StringUtils";
import { CartIcon, PicIcon, RightArrowWithTailIcon } from "../common/AppIcons";

export default function ServiceRequestTile({
  serviceRequest,
  data,
  currentUser,
  srSelectCB,
}) {
  const [appointments, setAppointments] = React.useState([]);
  const [expand, setExpand] = React.useState(false);
  const [names, setNames] = React.useState([]);

  const convertToTimeZone = (date, timeZone) => {
    let timezone = serviceRequest.timezone;
    let requiredTimezoneOffset = moment().tz(timezone)?.utcOffset();

    return moment(date?.split("Z")[0])
      .add(requiredTimezoneOffset, "minutes")
      .format("MM/DD/YYYY hh:mm A");
  };

  useEffect(() => {
    let associateNames = [];
    let appointments = serviceRequest.appointments;
    appointments = appointments.sort(function (a, b) {
      return new Date(b.timelineStart) - new Date(a.timelineStart);
    });

    appointments = appointments.reverse();

    appointments = appointments.filter((appointment) => {
      return appointment.status !== "FINISHED";
    });

    console.log("appointments", appointments);

    let string = "";

    appointments[appointments.length - 1]?.associates.map(
      (associate, index) => {
        string += `${associate.firstName} ${associate.lastName}, `;

        if (index % 2 === 1) {
          associateNames.push(string);
          string = "";
        }
      }
    );

    if (
      (appointments[appointments.length - 1]?.associates || [])?.length % 2 ===
      1
    ) {
      associateNames.push(
        `${
          appointments[appointments.length - 1].associates[
            appointments[appointments.length - 1].associates.length - 1
          ].firstName
        } ${
          appointments[appointments.length - 1].associates[
            appointments[appointments.length - 1].associates.length - 1
          ].lastName
        }`
      );
    }

    // appointments[appointments.length - 1].map((item, index) => {
    //   let string = "";
    //   item.associates.map((associate, index) => {
    //     string += `${associate.firstName} ${associate.lastName}, `;
    //     console.log("string", string, index, index % 2);

    //     if (index % 2 === 1) {
    //       associateNames.push(string);
    //       string = "";
    //     }
    //   });
    //   if (item.associates.length % 2 === 1) {
    //     associateNames.push(
    //       `${item.associates[item.associates.length - 1].firstName} ${
    //         item.associates[item.associates.length - 1].lastName
    //       }`
    //     );
    //   }
    // });

    setNames(associateNames);

    setAppointments(appointments);
  }, [serviceRequest]);
  return (
    <div className="w-full border rounded px-6 py-4 border-newGray-600">
      <div className="flex flex-row items-center justify-between gap-5 flex-wrap">
        <div className="flex flex-row items-center gap-2">
          <span className="text-newBlue-400 font-semibold">
            #
            {`${serviceRequest.ticketId}-` +
              (!serviceRequest.isDraft
                ? `SR${serviceRequest.serviceRequestIndex}`
                : `I${serviceRequest.serviceRequestIndex}`)}
          </span>
          <span className="text-sm ml-1 px-2 py-1 rounded bg-newBlue-500 text-newBlue-600">
            {properString(serviceRequest.jobType)}
          </span>
          {serviceRequest.subCategory && (
            <span className="text-sm ml-1 px-2 py-1 rounded bg-newPink-100 text-newBlue-600">
              {properString(serviceRequest.subCategory)}
            </span>
          )}
        </div>

        <div className="flex flex-row">
          {serviceRequest.displayVendor && (
            <span className="text-sm ml-1 px-2 py-1 rounded bg-newRed-100 text-newRed-600">
              {serviceRequest.displayVendor.name}
            </span>
          )}
          <div className="px-2">
            <div className="text-sm flex flex-row items-center gap-1 rounded px-2 py-1 bg-newOrange-100 text-indigo-500">
              <div className="flex flex-row items-center">
                <CartIcon />
                <span className="px-1 text-newOrange-200">
                  {serviceRequest.materials.length || 0}
                </span>
              </div>
            </div>
          </div>
          <div className="text-sm flex flex-row items-center gap-1 rounded px-2 py-1 bg-indigo-100 text-indigo-500">
            <div className="flex flex-row items-center">
              <PicIcon />
              <span className="px-1">
                {(serviceRequest.mediaUrls || []).length}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-end justify-between flex-wrap gap-4">
        <div className="flex flex-col gap-1 font-semibold">
          <div className="flex flex-row items-center gap-x-2 flex-wrap">
            <span className="text-newGray-800 font-normal	">
              Booking Status :
            </span>
            <span className="text-newBlue-600 font-normal">
              {properString(serviceRequest.bookingStatus)}
            </span>
          </div>
          <div className="flex flex-row items-center gap-x-2 flex-wrap">
            <span className="text-newGray-800 font-normal	">Date & Time :</span>
            <span className="text-newBlue-600 font-normal">
              {appointments[appointments.length - 1]
                ? convertToTimeZone(
                    appointments[appointments.length - 1].timelineStart,
                    serviceRequest.timezone
                  )
                : ""}
            </span>
          </div>
          <div className="flex flex-row items-start gap-2">
            <span className="text-newGray-800 font-normal">Associates:</span>

            <div className="flex flex-col items-start">
              {names.map((name, index) => {
                if (expand) {
                  return (
                    <span className="text-newBlue-600 font-normal" key={index}>
                      {name}
                    </span>
                  );
                } else {
                  if (index < 1) {
                    return (
                      <span
                        className="text-newBlue-600 font-normal"
                        key={index}
                      >
                        {name}
                      </span>
                    );
                  }
                }
              })}

              {names.length > 1 && (
                <button
                  className="text-xs focus:outline-none underline text-indigo-700 hover:text-indigo-600 cursor-pointer"
                  onClick={() => {
                    setExpand(!expand);
                  }}
                >
                  {expand ? "View Less" : "View More"}
                </button>
              )}
            </div>
          </div>
        </div>
        <div>
          <button
            className="text-newBlue-600 bg-newGray-900 hover:bg-gray-100 rounded text-sm px-2 py-1 flex flex-row items-center gap-1 font-normal"
            onClick={() => {
              srSelectCB(serviceRequest);
            }}
          >
            View Details <RightArrowWithTailIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
