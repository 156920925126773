import React from "react";

import Multiselect from "multiselect-react-dropdown";

import { getSubCategory } from "../associates/CreateAssociate";
import { CrossIcon } from "../common/AppIcons";

// Import the helper function

export default function VendorFilter({
  selectedSubscriber,
  setSelectedSubscriber,
  selectedCategories,
  setSelectedCategories,
  zipCodes,
  setZipCodes,
  onClose,
  onReset,
  view,
}) {
  const [zipCode, setZipCode] = React.useState("");

  const handleAddZipCode = (code) => {
    if (code.length === 5 && /^\d+$/.test(code)) {
      setZipCodes([...zipCodes, code]);
      setZipCode("");
    }
  };

  const handleRemoveZipCode = (code) => {
    setZipCodes(zipCodes.filter((item) => item !== code));
  };

  // Get all subcategories without duplicates
  const getAllSubCategories = () => {
    const categories = ["HANDYMAN", "ELECTRICIAN", "PLUMBER", "SPECIALIST"];
    const allSubCategories = new Set();

    categories.forEach((category) => {
      getSubCategory(category).forEach((subCat) => {
        allSubCategories.add(subCat.value);
      });
    });

    return Array.from(allSubCategories).map((value) => ({
      label: value
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" "),
      value: value,
    }));
  };

  const handleCategorySelect = (selected) => {
    // Flatten the selected values array to just contain the values
    const flattenedValues = selected.map((item) => item.value);
    setSelectedCategories(flattenedValues);
  };

  const handleReset = () => {
    setSelectedCategories([]);
    setZipCodes([]);
    if (view !== "HUB") {
      setSelectedSubscriber({ subscriberType: "ALL" });
    }
    onReset();
  };

  return (
    <div className="p-4 w-96">
      <div className="flex justify-between items-center mb-4">
        <div className="font-semibold text-xl">Filters</div>
        <button
          onClick={handleReset}
          className="text-sm text-newBlue-400 hover:underline"
        >
          Reset Filters
        </button>
      </div>

      {view !== "HUB" && (
        <div className="mb-4">
          <div className="font-medium mb-2">Subscriber Type</div>
          <select
            className="w-full bg-white p-2 border border-gray-300 rounded"
            value={selectedSubscriber.subscriberType}
            onChange={(e) =>
              setSelectedSubscriber({ subscriberType: e.target.value })
            }
          >
            <option value="ALL">All</option>
            <option value="CONTRACTOR">Contractor</option>
            <option value="PMC">PMC</option>
          </select>
        </div>
      )}

      <div className="mb-4">
        <div className="font-medium mb-2">Sub Categories</div>
        <Multiselect
          options={getAllSubCategories()}
          selectedValues={selectedCategories.map((value) => ({
            label: value
              .split("_")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" "),
            value: value,
          }))}
          displayValue="label"
          showCheckbox
          onSelect={handleCategorySelect}
          onRemove={handleCategorySelect}
          customCloseIcon={<CrossIcon />}
          style={{
            chips: {
              background: "rgba(240, 242, 251, 1)",
              color: "rgba(18, 37, 157, 1)",
            },
          }}
        />
      </div>

      <div className="mb-4">
        <div className="font-medium mb-2">Zipcodes</div>
        <div className="bg-white p-1 flex border border-gray-300 rounded">
          <input
            className="p-1 px-2 outline-none w-full"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddZipCode(zipCode);
              }
            }}
            placeholder="Enter zipcode"
          />
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
          {zipCodes.map((code) => (
            <div
              key={code}
              className="flex items-center bg-blue-50 px-2 py-1 rounded"
            >
              <span>{code}</span>
              <button
                onClick={() => handleRemoveZipCode(code)}
                className="ml-2 focus:outline-none"
              >
                <CrossIcon />
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end gap-2">
        <button
          onClick={onClose}
          className="px-4 py-2 border border-gray-300 rounded"
        >
          Cancel
        </button>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-blue-600 text-white rounded"
        >
          Apply
        </button>
      </div>
    </div>
  );
}
