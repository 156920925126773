import React, { useEffect } from "react";

import AccountCircle from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SearchIcon from "@mui/icons-material/Search";
import { Badge, ListItemText, ListItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { navigate } from "raviger";

import { alertError } from "../../actions/AlertActions";
import { getNotifications, markNotificationAsRead } from "../../api/Api";
import dispatcher from "../../dispatcher";
import activeUserStore from "../../store/ActiveUserStore";
import TaskRouterUI from "../crm/TaskRouterUI";
import { FastForwardIcon } from "./AppIcons";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.2),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const fetchNotifications = (callback) =>
  getNotifications({ page: 0, size: 5 })
    .then(callback)
    .catch((_) =>
      alertError("Couldn't Connect to Server, Check your Connection")
    );

export default function NavBar({
  className,
  menuClassName,
  onClick,
  children,
}) {
  const classes = useStyles();

  const [notifications, setNotifications] = React.useState({
    notifications: {
      content: [],
    },
    unreadCount: 0,
    animate: false,
  });
  const [searchText, setSearchText] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [crm, setCRM] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isNotificationsMenuOpen = Boolean(notificationsAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const refreshNotificationsCB = (notificationsResponse) => {
    // Logic to mark `animate` as true if there are new notifications
    // Compare the first notification in the new list, to check if it matches
    // the first or second notification in the old list
    setNotifications((prev) => {
      if (notificationsResponse.notifications.content.length === 0) {
        return {
          ...notificationsResponse,
          animate: false,
        };
      }
      if (notificationsResponse.unreadCount === 0) {
        return {
          ...notificationsResponse,
          animate: false,
        };
      }
      const currentNotifications = prev.notifications.content;
      const newNotifications = notificationsResponse.notifications.content;
      if (currentNotifications.length > 0 && newNotifications.length > 0) {
        if (
          currentNotifications[0].notificationId ===
            newNotifications[0].notificationId ||
          currentNotifications[1].notificationId ===
            newNotifications[0].notificationId
        ) {
          return {
            ...notificationsResponse,
            animate: false,
          };
        }
      }
      return {
        ...notificationsResponse,
        animate: true,
      };
    });
  };
  const handleAction = (action) =>
    action.actionType === "NOTIFICATION" &&
    action.topic.includes("/notifications/") &&
    fetchNotifications(refreshNotificationsCB);

  useEffect(() => {
    // let notificationCron = setInterval(()=>{
    // }, 10000);

    fetchNotifications(refreshNotificationsCB);

    dispatcher.register(handleAction);
    window.dispatcher = dispatcher;

    // return () => {
    //   clearInterval(notificationCron);
    // }
  }, []);

  const markAsRead = ({ notificationId, isRead }) => {
    !isRead &&
      markNotificationAsRead(notificationId)
        .then((_) => fetchNotifications(refreshNotificationsCB))
        .catch((_) => {});
    fetchNotifications(refreshNotificationsCB);
  };

  const readNotification = ({ notificationId, link, isRead }) => {
    !isRead &&
      markNotificationAsRead(notificationId)
        .then((_) => fetchNotifications(refreshNotificationsCB))
        .catch((_) => {});
    navigate(link);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuOpen = (event) => {
    setNotifications((prev) => ({
      ...prev,
      animate: false,
    }));
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleNotificationsMenuClose = () => {
    setNotificationsAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    activeUserStore.logoutUser();
  };
  const menuId = "primary-search-account-menu";
  const notificationsMenuId = "primary-notifications-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        {activeUserStore.getActiveUser().firstName}
      </MenuItem>
      <MenuItem
        test-id="logout"
        onClick={(_) => {
          handleLogout();
          handleMenuClose();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
  const renderNotificationsMenu = (
    <Menu
      anchorEl={notificationsAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={notificationsMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isNotificationsMenuOpen}
      onClose={handleNotificationsMenuClose}
      className="mt-10 w-full "
    >
      <div className="justify-center items-center ">
        {notifications.notifications.content.map((notification) => (
          <ListItem
            key={`${notification.notificationId}`}
            disableGutters
            className={
              "px-4 cursor-pointer hover:bg-gray-300" +
              (notification.isRead ? "" : " bg-gray-200")
            }
            onClick={(_) => readNotification(notification)}
            secondaryAction={
              notification.isRead ? (
                <></>
              ) : (
                <IconButton
                  className="mx-2"
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    markAsRead(notification);
                  }}
                >
                  <FastForwardIcon />
                </IconButton>
              )
            }
          >
            <ListItemText
              key={`${notification.notificationId}`}
              primary={notification.title}
              secondary={notification.content}
              className="mr-8"
            />
          </ListItem>
        ))}
        <button
          className="text-sm text-blue-700 py-2 px-4 rounded justify-center items-center cursor-pointer"
          onClick={() => {
            handleNotificationsMenuClose();
            navigate("/notifications");
          }}
        >
          <div>View All Notifications</div>
        </button>
      </div>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        test-id="logout-mobile"
        onClick={(_) => {
          handleMobileMenuClose();
        }}
      >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu-profile"
          aria-haspopup="true"
          color="inherit"
          size="large"
        >
          <AccountCircle />
        </IconButton>
        {activeUserStore.getActiveUser().firstName}
      </MenuItem>
      <MenuItem
        onClick={(_) => {
          handleLogout();
          handleMobileMenuClose();
        }}
      >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu-logout"
          aria-haspopup="true"
          color="inherit"
          size="large"
        >
          <AccountCircle />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        className={className}
        style={{ backgroundColor: "#ffffff" }}
      >
        <Toolbar className="text-gray-900">
          <IconButton
            edge="start"
            className={menuClassName}
            color="inherit"
            onClick={onClick}
            aria-label="open drawer"
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <span className="text-yellow-600 text-2xl">Squad</span>
            <span className="text-blue-900 text-2xl">Hub</span>
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>

            <form
              onSubmit={(e) => {
                console.log("Navigate To", `/clients`, false, {
                  search: searchText,
                });
                e.preventDefault();
                navigate(`/clients?search=${searchText}`);
              }}
            >
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={searchText}
                onChange={(e) => {
                  console.log("Change");
                  setSearchText(e.target.value);
                }}
                inputProps={{ "aria-label": "search" }}
              />
              <button type="submit" className="hidden"></button>
            </form>
          </div>
          <div className={classes.grow} id="test_logo">
            <div className=" items-center justify-center self-center mr-52 hidden md:flex">
              <div className="flex items-center">
                <BusinessIcon className="h-8 w-8 mr-2 text-blue-600" />
                <Typography
                  variant="h6"
                  noWrap
                  className="font-bold tracking-wider bg-gradient-to-r from-blue-500 to-blue-700 bg-clip-text text-transparent  border-blue-600 px-4 py-2"
                >
                  {activeUserStore.getActiveUser().vendor.name}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.sectionDesktop}>
            <TaskRouterUI />
            <IconButton
              aria-label="show new notifications"
              aria-controls={notificationsMenuId}
              onClick={() => {
                dispatcher.dispatch({
                  actionType: "CRM",
                  open: !crm,
                  variant: "",
                });
                setCRM((prev) => !prev);
              }}
              color="inherit"
              size="large"
            >
              {crm ? (
                <PhoneDisabledIcon className="h-6 w-6" />
              ) : (
                <PhoneEnabledIcon className="h-6 w-6" />
              )}
            </IconButton>
            <IconButton
              aria-label="show new notifications"
              aria-controls={notificationsMenuId}
              onClick={() => navigate("/todo")}
              color="inherit"
              size="large"
            >
              <Badge color="secondary" overlap="rectangular">
                <PlaylistAddCheckIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="show new notifications"
              aria-controls={notificationsMenuId}
              onClick={handleNotificationsMenuOpen}
              color="inherit"
              className={notifications.animate ? "animate-bounce" : ""}
              size="large"
            >
              <Badge
                badgeContent={notifications.unreadCount}
                color="secondary"
                overlap="rectangular"
              >
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show new notifications"
              aria-controls={notificationsMenuId}
              onClick={handleNotificationsMenuOpen}
              color="inherit"
              className={
                "w-7 pr-3" + (notifications.animate ? "animate-bounce" : "")
              }
              // size="small"
            >
              <Badge
                badgeContent={notifications.unreadCount}
                color="secondary"
                overlap="rectangular"
              >
                <NotificationsNoneIcon className="w-8 text-lg" />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="large"
              className={"pr-0 ml-2"}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderNotificationsMenu}
      {renderMenu}
      {children}
    </div>
  );
}
