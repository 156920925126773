import { useEffect, useState } from "react";



import { alertError, alertSuccess } from "../../../actions/AlertActions";
import { getInvoices, sendReminder } from "../../../api/Api";
import { properString } from "../../../utils/StringUtils";
import { SearchIcon } from "../../common/AppIcons";
import MaterialModal from "../../common/MaterialModal";
import MaterialTable from "../../common/MaterialTable";
import MaterialForm from "../../common/form/MaterialForm";
import InvoiceForm from "../InvoiceForm";


type AssignTicketProps = {
  ticketId?: number;
  flow?: "LAYOUT" | "CLIENT";
  clientId?: number;
  contactId?: number;
  buildingId?: number;
};

type InvoiceItem = {
  id: number;
  estimateId: number;
  total: number;
  status: string;
  formattedInvoiceDate: string;
  ticket: {
    ticketId: number;
    title: string;
    clientName: string;
    streetAddress1: string;
    unitNumber: number;
    city: string;
  };
};

export default function ListInvoices({
  ticketId,
  flow,
  clientId,
  contactId,
  buildingId,
}: AssignTicketProps) {
  const [listData, setListData] = useState<InvoiceItem[]>([]);
  const [searchText, setSearchText] = useState("");

  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({
    ticketId: 0,
    estimateId: 0,
    invoiceId: 0,
  });
  const [activeTab, setActiveTab] = useState("SENT");

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [reminder, setReminder] = useState(false);
  const [filter, setFilter] = useState({
    status: "ALL",
  });
  useEffect(() => {
    setListData([]);
  }, []);

  useEffect(() => {
    setShowInvoice(false);
  }, [reminder]);

  useEffect(() => {
    getInvoices(
      page,
      size,
      ticketId ?? "",
      searchText ?? "",
      clientId ?? "",
      contactId ?? "",
      buildingId ?? "",
      filter.status === "ALL" ? "" : filter.status,
      activeTab ?? "SENT"
    )
      .then((data) => {
        setListData(data.content);
        setTotalElements(data.totalElements);
      })
      .catch((_) =>
        alertError("Couldn't Connect to Server, Check your Connection")
      );
  }, [
    page,
    size,
    searchText,
    buildingId,
    clientId,
    contactId,
    ticketId,
    filter.status,
    activeTab,
  ]);

  const getTableHead = () => {
    if (flow === "LAYOUT")
      return [
        { id: "ticketId", label: "Ticket #" },
        { id: "ticketTitle", label: "Ticket Title" },
        { id: "invoiceNumber", label: "Invoice Number" },
        { id: "invoiceDate", label: "Invoice Date" },
        { id: "total", label: "Total($)" },
        { id: "status", label: "Status" },
        { id: "clientName", label: "Client Name" },
        { id: "address", label: "Address" },
        { id: "reminder", label: "" },
      ];
    else if (flow === "CLIENT")
      return [
        { id: "ticketId", label: "Ticket #" },
        { id: "ticketTitle", label: "Ticket Title" },
        { id: "invoiceNumber", label: "Invoice Number" },
        { id: "invoiceDate", label: "Invoice Date" },
        { id: "total", label: "Total($)" },
        { id: "status", label: "Status" },
        { id: "address", label: "Address" },
        { id: "reminder", label: "" },
      ];
    return [
      { id: "invoiceNumber", label: "Invoice Number" },
      { id: "invoiceDate", label: "Invoice Date" },
      { id: "total", label: "Total($)" },
      { id: "status", label: "Status" },
      { id: "reminder", label: "" },
    ];
  };

  const getTableData = () => {
    let data = [];

    data = listData?.map((item: InvoiceItem) => {
      if (flow === "LAYOUT") {
        return {
          id: item.id,
          rowStyle: item.status === "PAID" ? "bg-green-200" : "",
          data: [
            item.ticket.ticketId,
            item.ticket.title,
            `INV${item.id}`,
            item.formattedInvoiceDate,
            item.total.toFixed(2),
            properString(item.status),
            item.ticket.clientName,
            `${item.ticket.streetAddress1}, ${
              item.ticket.unitNumber ? `${item.ticket.unitNumber},` : ""
            } ${item.ticket.city}`,
            {
              render: (
                <>
                  {(item.status === "SENT" || item.status === "OVERDUE") &&
                    activeTab === "SENT" && (
                      <button
                        className="flex w-max p-2 items-center rounded bg-blue-300"
                        onClick={(e) => {
                          e.preventDefault();
                          setReminder(!reminder);
                          sendReminder(item.id)
                            .then(() =>
                              alertSuccess("Reminder sent  successfully")
                            )
                            .catch(() =>
                              alertError("Error while sending Reminder")
                            );
                        }}
                      >
                        <span className="pl-2 text-sm font-medium">
                          Send Reminder
                        </span>
                      </button>
                    )}
                </>
              ),
            },
          ],
        };
      } else if (flow === "CLIENT") {
        return {
          id: item.id,
          rowStyle: item.status === "PAID" ? "bg-green-200" : "",
          data: [
            item.ticket.ticketId,
            item.ticket.title,
            `INV${item.id}`,
            item.formattedInvoiceDate,
            item.total.toFixed(2),
            properString(item.status),
            `${item.ticket.streetAddress1}, ${
              item.ticket.unitNumber ? `${item.ticket.unitNumber}, ` : ""
            } ${item.ticket.city}`,
            {
              render: (
                <>
                  {(item.status === "SENT" || item.status === "OVERDUE") &&
                    activeTab === "SENT" && (
                      <button
                        className="flex w-max p-2 items-center rounded bg-blue-300"
                        onClick={(e) => {
                          e.preventDefault();
                          setReminder(!reminder);
                          sendReminder(item.id)
                            .then(() =>
                              alertSuccess("Reminder sent  successfully")
                            )
                            .catch(() =>
                              alertError("Error while sending Reminder")
                            );
                        }}
                      >
                        <span className="pl-2  text-sm font-medium">
                          Send Reminder
                        </span>
                      </button>
                    )}
                </>
              ),
            },
          ],
        };
      }
      return {
        id: item.id,
        rowStyle: item.status === "PAID" ? "bg-green-200" : "",
        data: [
          `INV${item.id}`,
          item.formattedInvoiceDate,
          item.total.toFixed(2),
          properString(item.status),
          {
            render: (
              <>
                {(item.status === "SENT" || item.status === "OVERDUE") &&
                  activeTab === "SENT" && (
                    <button
                      className="flex w-max p-2 items-center rounded bg-blue-300"
                      onClick={(e) => {
                        e.preventDefault();
                        setReminder(!reminder);
                        sendReminder(item.id)
                          .then(() => {
                            alertSuccess("Reminder sent  successfully");
                          })
                          .catch(() =>
                            alertError("Error while sending Reminder")
                          );
                      }}
                    >
                      <span className="pl-2  text-sm font-medium">
                        Send Reminder
                      </span>
                    </button>
                  )}
              </>
            ),
          },
        ],
      };
    });
    return data;
  };

  return (
	//Add a tab switch for Recevied and Sent Invoices
	<div>
    <div className="flex flex-row gap-4 mb-4">
      <button
        className={`px-4 py-2 rounded-lg ${
          activeTab === 'SENT' 
            ? 'bg-blue-600 text-white' 
            : 'bg-gray-200 text-gray-700'
        }`}
        onClick={() => setActiveTab('SENT')}
      >
        Outgoing Invoices
      </button>
      <button 
        className={`px-4 py-2 rounded-lg ${
          activeTab === 'RECEIVED'
            ? 'bg-blue-600 text-white'
            : 'bg-gray-200 text-gray-700'
        }`}
        onClick={() => setActiveTab('RECEIVED')}
      >
        Received Invoices  
      </button>
    </div>

    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col md:flex-row w-full justify-between">
        <p className="font-semibold text-newBlue-400 w-80 self-start my-4">
          <div className="relative ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon />
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-72 p-2 pl-10 text-sm text-gray-900 border border-gray-900 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-gray-800"
              placeholder="Search..."
              required
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </p>
        <MaterialForm
          className="w-72 mb-4 mx-1"
          noSelect={true}
          data={filter}
          renderArray={[
            {
              name: "status",
              inputType: "SELECT",
              defaultValue: "ALL",
              options: [
                { label: "All", value: "ALL" },
                { label: "Draft", value: "DRAFT" },
                { label: "Paid", value: "PAID" },
                { label: "Sent", value: "SENT" },
                { label: "Overdue", value: "OVERDUE" },
              ],
              label: "Status",
            },
          ]}
          onChange={(update: any) => {
            const { value } = update;
            setFilter({ status: value });
          }}
        />
      </div>
      <MaterialTable
        data={getTableData()}
        head={getTableHead()}
        headRenderColor="text-newGray-300"
        defaultOrder="desc"
        pageRows={size}
        onClickCB={(selection: number) => {
          const selectedItem = listData.find(
            (item: InvoiceItem) => item.id === selection
          );
          setSelectedInvoice({
            estimateId: selectedItem?.estimateId ?? 0,
            invoiceId: selection,
            ticketId: selectedItem?.ticket.ticketId ?? 0,
          });
          setShowInvoice(true);
        }}
        totalData={totalElements}
        currentPage={page}
        paginationCB={(value: number) => {
          setPage(value);
        }}
        rowChangeCB={(value: number) => {
          setSize(value);
        }}
        loading={undefined}
        defaultOrderBy={undefined}
        multiSelect={undefined}
        searchText={undefined}
        setSelected={undefined}
      />

      <MaterialModal
        open={showInvoice}
        setOpen={(_) => {
          setShowInvoice((current) => !current);
          getInvoices(
            page,
            size,
            ticketId ?? "",
            searchText ?? "",
            clientId ?? "",
            contactId ?? "",
            buildingId ?? "",
            filter.status === "ALL" ? "" : filter.status,
            activeTab ?? "SENT"
          ).then((data) => {
            setListData(data.content);
            setTotalElements(data.totalElements);
          });
        }}
        label="estimate-modal"
        describedBy="create-view-estimate"
        width={90}
        className="px-1 py-1"
      >
        <InvoiceForm
          invoiceId={selectedInvoice.invoiceId}
          className="px-1 py-1"
        />
      </MaterialModal>
    </div>
	</div>
  );
}