import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import SnoozeIcon from "@mui/icons-material/Snooze";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { assignOwner, snoozeTicket } from "../../api/Api";
import { getFormattedDate } from "../../utils/DateUtils";
import {
  DocumentKanbanIcon,
  HourGlass,
  InvoiceIcon,
  PersonWithIcon,
} from "../common/AppIcons";
import MaterialModal from "../common/MaterialModal";
import SelectOwner from "../settings/SelectOwner";
import TicketOwner from "../tickets/TicketOwner";

const grid = 8;

const formatInvoiceStatus = (status) => {
  if (status === "NOT_INVOICED") {
    return "Not Invoiced";
  } else if (status === "PARTIALLY_INVOICED") {
    return "Partially Invoiced";
  } else {
    return "Invoiced";
  }
};

export const estimateStatusFormatted = {
  NOT_SENT: "Not Sent",
  ACCEPTED: "Accepted",
  UNSENT: "Unsent",
  SENT: "Sent",
  PARTIALLY_ACCEPTED: "Partially Accepted",
  REJECTED: "Rejected",
  ACKNOWLEDGED: "Acknowledged",
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  //   background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
});

const ticketModal = (state) => {
  console.log(state);
};

export default function Board({
  data,
  board,
  provided,
  snapshot,
  pipeline,
  updateTicket,
  pipelineLoad,
  territory,
}) {
  const [items, setItems] = useState([]);

  const [expanded, setExpanded] = useState(items.length > 0 ? true : false);

  const [snoozeModal, setSnoozeModal] = useState(false);
  const [changeOwner, setChangeOwner] = useState({
    ticket: null,
    ownerId: null,
    display: false,
  });

  const [snoozeDays, setSnoozeDays] = useState(2);
  const [ticketId, setTicketId] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (days) => {
    setSnoozeDays(days);

    setAnchorEl(null);
  };

  useEffect(() => {
    setExpanded(
      data.filter((ticket) => ticket.stageId === board.stageId).length > 0
        ? true
        : false
    );
  }, [data, board]);

  useEffect(() => {
    setItems(data.filter((ticket) => ticket.stageId === board.stageId));
  }, [data]);

  return (
    <>
      {expanded ? (
        <div
          className={
            "m-2 p-2 w-72 flex-shrink-0 rounded " +
            (snapshot.isDraggingOver ? "bg-blue-200" : "bg-gray-200")
          }
          id={board.stage === "Closed Won" ? "closed_won" : board.stage}
          ref={provided.innerRef}
          // style={getListStyle(snapshot.isDraggingOver)}
          {...provided.droppableProps}
        >
          <div className="py-1">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div>
                  <h3 className="text-sm">{board.stage}</h3>
                </div>
                <div>
                  <FastRewindIcon
                    className="cursor-pointer"
                    onClick={(_) => setExpanded(false)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-sm mt-2 h-lscreen overflow-auto scrollbar-hidden">
            {items.map((ticket, index) => (
              <Draggable
                key={`${ticket.ticketId}`}
                draggableId={`${ticket.ticketId}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div
                      className={
                        "flex flex-col p-2 mt-1 lb rounded cursor-pointer hover:bg-gray-300 bg-white kanban-cards " +
                        (new Date() > new Date(Date.parse(ticket.deadline))
                          ? "border-red-500 border-2"
                          : "")
                      }
                      onClick={(_) => {
                        updateTicket({
                          ...ticket,
                          pipeline: ticket.pipelineId,
                        });
                      }}
                    >
                      <div className="flex items-center">
                        {ticket.priority && (
                          <div className="flex-row w-full">
                            <span className="inline-flex sm:whitespace-pre items-center rounded px-2 py-0.5 text-xs font-medium bg-red-600 text-white">
                              PRIORITY
                            </span>
                          </div>
                        )}
                        &nbsp;
                        <div className="flex flex-row justify-between w-full mb-1">
                          {ticket.type === "EXTERNAL" ? (
                            <div className="flex justify-center w-20 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal py-0.5">
                              External
                            </div>
                          ) : ticket.parentTicketId ? (
                            <div className="flex justify-center w-20 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal py-0.5">
                              The Hub
                            </div>
                          ) : (
                            <div />
                          )}
                          <div className="self-end">
                            <span
                              className="inline-flex items-center rounded px-2 py-0.5 text-xs font-medium"
                              style={{ color: "#4B4B4B" }}
                            >
                              #T{ticket.ticketId}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <p
                          className="text-base font-semibold"
                          style={{ color: "#0023FF" }}
                        >
                          {ticket.title}
                        </p>
                      </div>
                      <div className="flex items-center py-2">
                        <div className="flex-row">
                          <PersonWithIcon className="mx-1" />
                        </div>
                        <div className="flex-row px-1">
                          <p className="text-sm">{ticket.clientName}</p>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center">
                          <div className="flex-row px-1 ">
                            <HourGlass className=" h-4 w-4 mx-1" />
                          </div>
                          <div className="flex-row px-1 ">
                            <p>{getFormattedDate(ticket.deadline)}</p>
                          </div>
                          {new Date() >
                            new Date(Date.parse(ticket.deadline)) && (
                            <div className="flex-row">
                              <SnoozeIcon
                                className="mx-1 text-red-500 cursor-pointer h-4 w-4"
                                onClick={(event) => {
                                  setSnoozeModal(true);
                                  setTicketId(ticket.ticketId);

                                  event.stopPropagation();
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {pipeline === "Vacant Unit" && (
                          <div
                            className="self-end"
                            onClick={(e) => {
                              e.stopPropagation();
                              setChangeOwner({
                                ticket: ticket.ticketId,
                                ownerId: ticket.ownerId,
                                display: true,
                              });
                            }}
                          >
                            <TicketOwner ticket={ticket} />
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row justify-between w-full items-center py-1">
                        <div className="flex flex-row gap-x-2 gap-y-2 items-center flex-wrap">
                          {ticket.estimatestatus ? (
                            <span
                              className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus"
                              style={{
                                color: "#7B61FF",
                                backgroundColor: "#E4E2F7",
                              }}
                            >
                              <DocumentKanbanIcon className="h-4 w-4" /> &nbsp;{" "}
                              {estimateStatusFormatted[ticket.estimatestatus]}
                            </span>
                          ) : (
                            <>
                              {pipeline !== "Vacant Unit" && (
                                <span
                                  className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium"
                                  style={{
                                    color: "#7B61FF",
                                    backgroundColor: "#E4E2F7",
                                  }}
                                >
                                  <DocumentKanbanIcon className="h-4 w-4" />{" "}
                                  &nbsp;
                                  {"No Estimate"}
                                </span>
                              )}
                            </>
                          )}
                          {ticket.invoiceStatus &&
                            pipeline !== "Vacant Unit" && (
                              <span
                                className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium"
                                style={{
                                  color: "#23AF2A",
                                  backgroundColor: "#D4EAD7",
                                  // opacity: "0.15"
                                }}
                              >
                                <InvoiceIcon className="h-4 w-4" /> &nbsp;
                                {formatInvoiceStatus(ticket.invoiceStatus)}
                              </span>
                            )}
                        </div>
                        {pipeline !== "Vacant Unit" && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              setChangeOwner({
                                ticket: ticket.ticketId,
                                ownerId: ticket.ownerId,
                                display: true,
                              });
                            }}
                          >
                            <TicketOwner ticket={ticket} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {items.length > 4 && (
              <p className="mt-3 text-center text-grey-dark">That's all...</p>
            )}
          </div>
          {provided.placeholder}
        </div>
      ) : (
        <div
          className={
            "rounded m-2 p-2 " +
            (snapshot.isDraggingOver ? "bg-blue-200" : "bg-gray-200")
          }
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <div className="flex flex-col justify-between py-1">
            <FastForwardIcon
              className="cursor-pointer"
              onClick={(_) => setExpanded(true)}
            />
            {board.stage.split("").map((letter, renderIndex) => (
              <h3 key={renderIndex} className="text-sm text-center h-4 w-full">
                {letter}
              </h3>
            ))}
          </div>
        </div>
      )}

      {changeOwner.display && (
        <MaterialModal
          open={changeOwner.display !== false}
          setOpen={(_) =>
            setChangeOwner((current) => {
              return {
                ...current,
                display: false,
              };
            })
          }
          label="new-user-modal"
          describedby="create-new-user"
        >
          <SelectOwner
            ticketId={changeOwner.ticket}
            currentOwner={changeOwner.ownerId}
            territory={territory}
            supervisorStage={board.stage === "Assigned to Supervisor"}
            changeOwnerCB={(data) => {
              assignOwner(data).then(() => {
                pipelineLoad();
                setChangeOwner((current) => {
                  return {
                    ...current,
                    display: false,
                  };
                });
              });
            }}
          />
        </MaterialModal>
      )}

      {snoozeModal && (
        <MaterialModal
          open={snoozeModal ? true : false}
          setOpen={(_) => setSnoozeModal()}
          label="snooze-modal"
          describedby="snooze-modal"
        >
          <p>
            Snooze for{" "}
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {snoozeDays}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={(_) => {
                setAnchorEl(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {[...Array(10)].map((item, index) => (
                <MenuItem key={index} onClick={(_) => handleClose(index + 1)}>
                  {index + 1}
                </MenuItem>
              ))}
            </Menu>
            days
          </p>

          <Button
            onClick={() => {
              snoozeTicket(ticketId, snoozeDays)
                .then(() => {
                  setSnoozeModal(false);
                  pipelineLoad();
                })
                .catch(() => {});
            }}
            color="primary"
            className="float-right p-4"
          >
            Snooze
          </Button>
          <Button
            onClick={(_) => setSnoozeModal(false)}
            className="float-right p-4"
          >
            Cancel
          </Button>
        </MaterialModal>
      )}
    </>
  );
}
