import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import posthog from "posthog-js";
import "tw-elements";

import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
  api_host: "https://app.posthog.com",
});

if (process.env.REACT_APP_SENTRY_DSN) {
  console.log("Initializing Error Reporting");
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    denyUrls: [/sdk\.twilio\.com\/js\/taskrouter\/v1\.21\/taskrouter.min.js/i],
    integrations: [new Integrations.BrowserTracing()],
    beforeSend: (event) => {
      console.log("Reporting to Sentry", event);
      // Check if event has `culprit` string
      if (event.culprit && event.culprit.length) {
        if (event.culprit.includes("taskrouter/v1.21/taskrouter")) {
          console.log("Dropping taskrouter events");
          return null;
        }
        return event;
      }
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
